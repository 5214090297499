import { createAsyncThunk } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { multiQueryStringParamBuilder, prepareAuthHeaders } from './helper';

const { services, users } = apiBasepath;
const bankInstructionApiPath = '/config/bankingInstructions';

export const fetchAllTransactionCompliancePaginatedTable = createAsyncThunk(
  'transactionApi/fetchAllTransactionCompliancePaginatedTable',
  async (initialParams, { getState, signal }) => {
    const baseQuery = fetchBaseQuery({
      baseUrl: `${api.endpoints.admin}`,
      prepareHeaders: prepareAuthHeaders,
      paramsSerializer: multiQueryStringParamBuilder,
    });

    let allTransactions = {};
    let allInvoices = {};
    let keepFetching = true;
    let currentParams = {
      ...initialParams, // Include the initial parameters
    };

    while (keepFetching) {
      // Use baseQuery from RTK Query for fetching
      const result = await baseQuery(
        {
          url: `${users}/transactions/compliance`,
          method: 'GET',
          params: currentParams,
          signal,
        },
        { getState, endpoint: 'getTransactionCompliancePaginatedTable' },
      );

      if (result.error) {
        throw new Error('Server error while fetching transactions');
      }

      const data = result.data;
      allTransactions = { ...allTransactions, ...data.transactions };
      allInvoices = { ...allInvoices, ...data.invoices };

      // Check if the paginationKeys object is empty to determine if we should keep fetching
      keepFetching = data.paginationKeys && Object.keys(data.paginationKeys).length !== 0;

      if (keepFetching) {
        let newParams = {};
        newParams = { ...initialParams };

        Object.keys(data.paginationKeys).forEach((key) => {
          newParams[`StartKey${key}`] = encodeURI(JSON.stringify(data.paginationKeys[key]));
        });

        // Update currentParams with the new StartKey for the next request
        currentParams = newParams;
      }
    }

    return { transactions: allTransactions, invoices: allInvoices };
  },
);

export const transactionApi = createApi({
  reducerPath: 'transactionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}`,
    prepareHeaders: prepareAuthHeaders,
    paramsSerializer: multiQueryStringParamBuilder,
  }),
  tagTypes: ['CREDIT', 'DEBIT', 'WITHDRAW', 'BankingInstructions', 'DefaultWithdrawConfig'],
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getTransactionTable: builder.query({
      query: (params) => ({
        url: `${users}/transactions`,
        method: 'GET',
        params,
      }),
    }),
    getTransactionComplianceTable: builder.query({
      query: (params) => ({
        url: `${users}/transactions/compliance`,
        method: 'GET',
        params,
      }),
    }),
    getTransactionComplianceCount: builder.query({
      query: (params) => ({
        url: `${users}/transactions/compliance/count`,
        method: 'GET',
        params,
      }),
    }),
    updatePaginatedTransactionTable: builder.mutation({
      query: (params) => ({
        url: `${users}/transactions`,
        method: 'GET',
        params,
      }),
    }),
    getWithdrawalGatingRules: builder.query({
      query: () => ({
        url: `${users}/config?paramName=withdrawGating`,
        method: 'GET',
      }),
    }),
    getCoinDiscountFactors: builder.query({
      query: () => ({
        url: `${users}/config?paramName=coinDiscountFactors`,
        method: 'GET',
      }),
    }),
    updateWithdrawalGatingRules: builder.mutation({
      query: (payload) => ({
        url: `${users}/config/newGating`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updateReorderWithdrawalGatingRules: builder.mutation({
      query: (payload) => ({
        url: `${users}/config/reorderGating`,
        method: 'PUT',
        body: payload,
      }),
    }),
    updateDeleteWithdrawalGatingRules: builder.mutation({
      query: (payload) => ({
        url: `${users}/config/deleteGating`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getTransactionAutoPilotTable: builder.query({
      keepUnusedDataFor: 2,
      query: (params) => ({
        url: `${users}/v2/depositaddresses`,
        method: 'GET',
        params,
      }),
    }),
    updatePaginatedTransactionAutoPilotTable: builder.mutation({
      query: (params) => ({
        url: `${users}/v2/depositaddresses`,
        method: 'GET',
        params,
      }),
      async onQueryStarted({ paginationKey, ...rest }, { queryFulfilled, dispatch }) {
        if (!paginationKey) return;
        try {
          const { data: paginatedData } = await queryFulfilled;
          dispatch(
            transactionApi.util.updateQueryData('getTransactionAutoPilotTable', rest, (draft) => {
              draft.data.push(...paginatedData.orders);
              draft.pagination = paginatedData.pagination;
            }),
          );
        } catch (e) {
          console.log('error', e);
        }
      },
    }),
    getCrystalAMLThreshold: builder.query({
      query: () => ({
        url: `${users}/config?paramName=crystalAMLThreshold`,
        method: 'GET',
      }),
    }),
    updateCrystalAMLThreshold: builder.mutation({
      query: (params) => {
        const { value, aquapayCustomerInvoicing } = params;

        return {
          url: `${users}/config/crystalAMLThreshold`,
          method: 'PUT',
          body: {
            ...(Object.keys(value).length && { value }),
            ...(Object.keys(aquapayCustomerInvoicing).length && { aquapayCustomerInvoicing }),
          },
        };
      },
    }),
    getWithdrawConfig: builder.query({
      query: () => ({
        url: `${users}/config?paramName=defaultWithdrawConfig`,
        method: 'GET',
      }),
      providesTags: ['DefaultWithdrawConfig'],
      transformResponse: (result) => {
        return result.map((r) => {
          // (XXX) JULIE `r` has this structure
          // [
          //     {
          //         "1INCH": {
          //             "fee": 6,
          //             "lowBal": 100000,
          //             "networkType": "ETH",
          //             "dynamicFee": "0.00003246900032469"
          //         }
          //     },
          // ]
          const coin = Object.keys(r)[0];
          const value = Object.values(r)[0];
          const newStructure = {
            ...value,
            coin,
            editable: true,
          };
          return newStructure;
        });
      },
    }),
    updateWithdrawConfig: builder.mutation({
      query: (payload) => {
        const values = {};
        for (let i = 0; i < payload.length; i += 1) {
          const { coin, fee, lowBal, networkType, dynamicFeeOverride, feeType, variable } =
            payload[i];
          values[`${coin}-${networkType}`] = { fee, lowBal, dynamicFeeOverride, feeType, variable };
        }
        return {
          url: `${users}/config`,
          method: 'PUT',
          body: {
            paramName: 'defaultWithdrawConfig',
            value: values,
          },
        };
      },
      invalidatesTags: ['DefaultWithdrawConfig'],
    }),
    getTransactionsStartDate: builder.query({
      // FIXME: check & filter `params[key] !== 'all'` in upstream
      query: (params) => ({
        url: `${users}/transactions`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { transactionType }) =>
        transactionType ? [transactionType] : [],
    }),
    approveUserWithdrawal: builder.mutation({
      query: (payload) => {
        const { selectedWithdrawOrder, adminUser, txHash, sourceAddress, clientNote, action, fee } =
          payload;
        const { username, itemDateTime, networkFee, txId, accountId } = selectedWithdrawOrder;
        return {
          url: `${users}/transactions/approveWithdraw`,
          method: 'PUT',
          body: {
            accountId,
            username,
            itemDateTime,
            adminUser,
            action,
            txId,
            txHash: typeof txHash === 'string' ? txHash.trim() : txHash,
            sourceAddress,
            clientNote,
            networkFee,
            fee,
          },
        };
      },
      transformResponse: (res) => res.Attributes,
    }),
    approveUserPayout: builder.mutation({
      query: (payload) => {
        const { selectedTransaction, adminUser, action, adminApprovalReason } = payload;
        const { username, itemDateTime, txId } = selectedTransaction;
        return {
          url: `${users}/transactions/approvePayout`,
          method: 'PUT',
          body: {
            username,
            adminUser,
            action,
            txId,
            adminApprovalReason,
            itemDateTime,
          },
        };
      },
      transformResponse: (response) => {
        return response?.Attributes ?? response;
      },
    }),
    approveUserDeposit: builder.mutation({
      query: (payload) => {
        const { selectedTransaction, adminUser, action, adminApprovalReason } = payload;
        const { username, itemDateTime, txId } = selectedTransaction;
        return {
          url: `${users}/transactions/approveDeposit`,
          method: 'PUT',
          body: {
            username,
            adminUser,
            action,
            txId,
            adminApprovalReason,
            itemDateTime,
          },
        };
      },
      transformResponse: (response) => {
        return response?.Attributes ?? response;
      },
    }),
    approveUserInvoice: builder.mutation({
      query: (payload) => {
        const { selectedTransaction, kycStatus, kycStatusReason, adminUser, action } = payload;
        const { invoiceId } = selectedTransaction;
        return {
          url: `${users}/invoice`,
          method: 'POST',
          body: {
            invoiceId,
            kycStatus,
            kycStatusReason,
            adminUser,
            action,
          },
        };
      },
      transformResponse: (res) => res?.Attributes ?? res,
    }),
    updateTransactionRecord: builder.mutation({
      // FIXME: error handling in upstream
      query: (payload) => ({
        url: `${users}/transactions/updateRecord`,
        method: 'PUT',
        body: payload,
      }),
    }),
    createTransaction: builder.mutation({
      // FIXME: error handling in upstream
      query: (payload) => ({
        url: `${users}/adminCreateTx`,
        method: 'POST',
        body: payload,
      }),
    }),
    amendTransaction: builder.mutation({
      query: (payload) => ({
        url: `${users}/adminAmendTx`,
        method: 'POST',
        body: payload,
      }),
    }),
    getSpecifiedUserBalance: builder.query({
      query: (username) => ({
        url: `${users}/userbalance?username=${username}`,
        method: 'GET',
      }),
    }),
    getSpecifiedUserBalanceTicker: builder.query({
      query: ({ username, ticker }) => ({
        url: `${users}/userbalance?username=${username}&ticker=${ticker}`,
        method: 'GET',
      }),
    }),
    getSpecifiedUserBalanceSymbol: builder.query({
      query: ({ username, symbol }) => ({
        url: `${users}/userbalance?username=${username}&symbol=${symbol}`,
        method: 'GET',
      }),
    }),
    getSettlementInfo: builder.query({
      query: (params) => ({
        url: `${users}/settlements`,
        method: 'GET',
        params,
      }),
    }),
    generateSettlements: builder.mutation({
      query: (payload) => ({
        url: `${users}/settlements`,
        method: 'POST',
        body: payload,
      }),
    }),
    /* Aquanow Admin Services */
    getInternalWatchAddress: builder.query({
      query: () => ({
        url: `${services}/getWatchOnlyAddresses`,
        method: 'GET',
      }),
    }),
    getBTCFullNodeBalance: builder.query({
      query: () => ({
        url: `${services}/wallet/balance?walletName=AutoWithdraw`,
        method: 'GET',
      }),
    }),
    previewTotalAmount: builder.query({
      query: (limit) => ({
        url: `${services}/btcautowithdrawreply?limit=${limit}`,
        method: 'GET',
      }),
    }),
    getAccountResources: builder.query({
      query: (params) => ({
        url: `${services}/accountResources`,
        method: 'GET',
        params,
      }),
    }),
    getSourceAddress: builder.query({
      query: (params) => ({
        url: `${services}/sourceAddress`,
        method: 'GET',
        params,
      }),
    }),
    batchWithdraw: builder.mutation({
      query: (payload) => ({
        url: `${services}/btcautowithdrawreply`,
        method: 'POST',
        body: payload,
      }),
    }),
    postTransferBalance: builder.mutation({
      // FIXME: custom error message 'Transfer was unsuccessful.'
      query: (payload) => ({
        url: `${services}/transferBalance`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateCredit: builder.mutation({
      query: (payload) => ({
        url: `${users}/userbalance/credit`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['CREDIT', 'DEBIT'],
    }),
    getFireblocksAutoWithdraw: builder.query({
      query: (params) => ({
        url: `${services}/fireblocksAutoWithdraw`,
        method: 'GET',
        params,
      }),
    }),
    postFireblocksAutoWithdraw: builder.mutation({
      query: (payload) => ({
        url: `${services}/fireblocksAutoWithdraw`,
        method: 'POST',
        body: payload,
      }),
    }),
    getAllFiatInstructions: builder.query({
      query: () => ({
        url: `${users}${bankInstructionApiPath}`,
        method: 'GET',
      }),
    }),
    getFiatInstructions: builder.query({
      query: (currency) => ({
        url: `${users}${bankInstructionApiPath}?currency=${currency}`,
        method: 'GET',
      }),
      transformResponse: (response, _, args) => {
        const fiat = args.toLowerCase();
        return response[fiat];
      },
      providesTags: ['BankingInstructions'],
    }),
    updateFiatBankingInstructions: builder.mutation({
      query: (payload) => ({
        url: `${users}${bankInstructionApiPath}`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(context, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          transactionApi.util.updateQueryData(
            'getFiatInstructions',
            context.symbol,
            (existingInstructions) => {
              return existingInstructions.map((i) => {
                if (i.bankIdentifier === context.bankIdentifier) {
                  return {
                    ...i,
                    ...context,
                  };
                }
                return i;
              });
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
    createFiatBankInstruction: builder.mutation({
      query: (payload) => ({
        url: `${users}${bankInstructionApiPath}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['BankingInstructions'],
    }),
    reorderFiatBankInstructions: builder.mutation({
      query: (payload) => ({
        url: `${users}${bankInstructionApiPath}/reorder`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(context, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          transactionApi.util.updateQueryData(
            'getFiatInstructions',
            context.symbol,
            () => context.bankingInstructions,
          ),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
    deleteFiatBankInstructions: builder.mutation({
      query: (payload) => ({
        url: `${users}${bankInstructionApiPath}`,
        method: 'DELETE',
        body: payload,
      }),
      async onQueryStarted(context, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          transactionApi.util.updateQueryData(
            'getFiatInstructions',
            context.symbol,
            (existingInstructions) => {
              return existingInstructions.filter(
                (i) => i.bankIdentifier !== context.bankIdentifier,
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
    getDynamicFeeMarkup: builder.query({
      query: () => ({
        url: `${users}/config?paramName=globalDynamicFeeMarkup`,
        method: 'GET',
      }),
      transformResponse: (result) => {
        return result.value;
      },
    }),
    updateDynamicFeeMarkup: builder.mutation({
      query: ({ value }) => ({
        url: `${users}/config`,
        method: 'PUT',
        body: {
          paramName: 'globalDynamicFeeMarkup',
          value,
        },
      }),
      async onQueryStarted(context, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          transactionApi.util.updateQueryData('getDynamicFeeMarkup', context.symbol, () => {
            return context.value;
          }),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetWithdrawalGatingRulesQuery,
  useGetTransactionTableQuery,
  useGetTransactionComplianceTableQuery,
  useGetTransactionComplianceCountQuery,
  useGetTransactionAutoPilotTableQuery,
  useGetCoinDiscountFactorsQuery,
  useGetWithdrawConfigQuery,
  useUpdateWithdrawConfigMutation,
  useGetTransactionsStartDateQuery,
  useApproveUserWithdrawalMutation,
  useUpdateTransactionRecordMutation,
  useCreateTransactionMutation,
  useAmendTransactionMutation,
  useGetSpecifiedUserBalanceQuery,
  useGetSpecifiedUserBalanceTickerQuery,
  useGetSpecifiedUserBalanceSymbolQuery,
  useGetInternalWatchAddressQuery,
  useGetBTCFullNodeBalanceQuery,
  usePreviewTotalAmountQuery,
  useGetAccountResourcesQuery,
  useGetSourceAddressQuery,
  useBatchWithdrawMutation,
  usePostTransferBalanceMutation,
  useUpdateCreditMutation,
  useUpdatePaginatedTransactionAutoPilotTableMutation,
  useUpdateWithdrawalGatingRulesMutation,
  useUpdateReorderWithdrawalGatingRulesMutation,
  useUpdateDeleteWithdrawalGatingRulesMutation,
  useUpdatePaginatedTransactionTableMutation,
  useGetFireblocksAutoWithdrawQuery,
  usePostFireblocksAutoWithdrawMutation,
  useGetCrystalAMLThresholdQuery,
  useUpdateCrystalAMLThresholdMutation,
  useApproveUserDepositMutation,
  useApproveUserPayoutMutation,
  useApproveUserInvoiceMutation,
  useGetSettlementInfoQuery,
  useGenerateSettlementsMutation,
  useGetFiatInstructionsQuery,
  useGetAllFiatInstructionsQuery,
  useUpdateFiatBankingInstructionsMutation,
  useCreateFiatBankInstructionMutation,
  useReorderFiatBankInstructionsMutation,
  useDeleteFiatBankInstructionsMutation,
  useUpdateDynamicFeeMarkupMutation,
  useGetDynamicFeeMarkupQuery,
  useGetBankingServicesDownstreamQuery,
} = transactionApi;
